import Modal from "react-bootstrap/Modal";
import Button from 'react-bootstrap/Button';
import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import ListGroup from 'react-bootstrap/ListGroup';



function LobbyRoomPicker({ showRoomPickerState, setRoomPickerState,currentRoomID,setCurrentRoomID,unityContext }) {


  const [XRevents, setXRevents] = useState([]);

  const handleButton0 = () => {
    setRoomPickerState(false);
  }

  const handleButton1 = () => {
    console.log(XRevents);
    uploadData(XRevents);
    setRoomPickerState(false);
  }

  const handleLoadRoomButton = (roomID) => {
    setCurrentRoomID(roomID)
    console.log("Requesting to load room: " + roomID);
    unityContext.send("LevelManager", "loadRoom", roomID); //currentRoomId ???
  }

  const handleSetToCurrentRoomButton = (index) =>{
    setXRevents(prevEvents => {
      const updatedEvents = { ...prevEvents };
      updatedEvents.results[index].item_meta_properties.XRevent = currentRoomID;
      return updatedEvents;
    });
  }

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch("https://xrevent-creator.de/upload/program/XRevents.json", { cache: "no-cache" });
      const data = await response.json();
      setXRevents(data);
    } catch (error) {
      console.log('Error fetching data:', error);
    }
  };

  const handleNameChange = (event, index) => {
    const { value } = event.target;
    setXRevents(prevEvents => {
      const updatedEvents = { ...prevEvents };
      updatedEvents.results[index].name.de = value;
      return updatedEvents;
    });
  };

  const handleXReventChange = (event, index) => {
    const { value } = event.target;
    setXRevents(prevEvents => {
      const updatedEvents = { ...prevEvents };
      updatedEvents.results[index].item_meta_properties.XRevent = value;
      return updatedEvents;
    });
  };

  const handleXRLocationChange = (event, index) => {
    const { value } = event.target;
    setXRevents(prevEvents => {
      const updatedEvents = { ...prevEvents };
      updatedEvents.results[index].item_meta_properties.XRlocation = parseInt(value);
      return updatedEvents;
    });
  };


  const uploadData = async (data) => {
    try {
      const response = await fetch("https://xrevent-creator.de/upload/program/uploadJson.php", {
        method: 'POST',
        /*         headers: {
                  'Content-Type': 'application/json'
                }, */
        body: JSON.stringify(data)
      });
      console.log(data);
      if (response.ok) {
        // Data sent successfully
        console.log('Data sent successfully to PHP script.');
      } else {
        // Error in sending data
        console.error('Failed to send data to PHP script.');
      }
    } catch (error) {
      console.error('Error occurred while sending data to PHP script:', error);
    }
  };

  return (
    <Modal
      show={showRoomPickerState}
      onHide={handleButton0}
      size="xl"
      onShow={fetchData}
      backdrop="static"
    >

      <Modal.Header closeButton>
        <Modal.Title>Lobby Room Selector
          <Button variant="warning" onClick={fetchData} style={{ marginLeft: "1rem" }}>Refresh Data</Button>
        </Modal.Title>
      </Modal.Header>

      {XRevents.results != null ?
        <Modal.Body>
          <div style={{ display: "flex", flexFlow: "row wrap" }}>
            {XRevents.results.map((event, index) => (
              <Card style={{ margin: "10px", width: "25rem" }}>
                <Card.Img style={{ height: "200px" }} variant="top" src={"https://xrevent-creator.de/upload/" + event.item_meta_properties.XRevent + "/preview.jpg"} />
                <ListGroup className="list-group-flush">
                  <ListGroup.Item key={1} style={{ display: "flex" }}>Name:
                    <input
                      style={{ flex: "1 0 auto", marginLeft: "0.5rem" }}
                      type="text"
                      value={event.name.de}
                      onChange={event => handleNameChange(event, index)}
                    /></ListGroup.Item>
                  <ListGroup.Item key={2} style={{ display: "flex", flexDirection: "column" }}>
                    <div>
                      <span>XRevent UUID:</span>
                      <Button  onClick={()=>handleSetToCurrentRoomButton(index)} size="sm" variant="secondary" style={{ marginLeft: "0.5rem", marginBottom: "0.5rem"}}> Change to current Room</Button>
                    </div>
                    <input
                      style={{ flex: "1 0 auto" }}
                      type="text"
                      value={event.item_meta_properties.XRevent}
                      onChange={event => handleXReventChange(event, index)}
                    /></ListGroup.Item>
                  <ListGroup.Item key={3}>XRlocation:
                    <input
                      style={{ width: "3rem", marginLeft: "0.5rem" }}
                      type="number"
                      value={event.item_meta_properties.XRlocation}
                      onChange={event => handleXRLocationChange(event, index)}
                    />
                    <Button  onClick={() => handleLoadRoomButton(event.item_meta_properties.XRevent)} variant="secondary" size="sm" style={{ marginLeft: "0.5rem" }}>Load this Room</Button></ListGroup.Item>
                </ListGroup>
              </Card>
            ))}
          </div>
        </Modal.Body>

        :

        <div style={{ color: "red", margin: "2rem", fontWeight: "700" }}>Error Fetching the Data.
          <Button variant="warning" onClick={fetchData} style={{ marginLeft: "2rem" }}>Retry</Button>
        </div>
      }

      <Modal.Footer>
        <Button variant="primary" onClick={handleButton1} >Save</Button>
        <Button variant="secondary" onClick={handleButton0} >Cancel</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default LobbyRoomPicker