import DMXItem from "./DMXItem"
import Card from "react-bootstrap/Card";
import ContextAwareToggle from "../ContextAwareToggle";
import Accordion from "react-bootstrap/Accordion";

const DmxItems = ({fileNames,manufacturerNames,fixtureNames,manufacturerIndexes, unityContext}) => {
    return (
        <Accordion defaultActiveKey="111">
            { manufacturerNames?.map((manufacturer,i) => {
              return <Card>
                        <Card.Header>
                            <ContextAwareToggle eventKey={i+111}>{manufacturer}</ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey={i+111}>
                            <Card.Body style={{ padding: '0px'}}>
                                    {manufacturerIndexes.map((mIndex,j)=>{
                                        return <>{mIndex == i &&
                                            <DMXItem itemname={fixtureNames[j]} manufacturer= {manufacturer} filename={fileNames[j]} unityContext={unityContext}></DMXItem>
                                        }</>
                                    })}
                                
                            </Card.Body>
                         </Accordion.Collapse>
                    </Card>})}
        </Accordion>
    )
}

export default DmxItems
