import { Row, Col, Form } from "react-bootstrap";
import { useState, useEffect } from "react";

function InspectorTransform({ unityContext, transform })  {
    
    
    const [posX, setPosX] = useState();
    const [posY, setPosY] = useState();
    const [posZ, setPosZ] = useState();

    const [rotX, setRotX] = useState();
    const [rotY, setRotY] = useState();
    const [rotZ, setRotZ] = useState();

    const [scaleX, setScaleX] = useState();
    const [scaleY, setScaleY] = useState();
    const [scaleZ, setScaleZ] = useState();


    useEffect(function () {
          console.log("InspectorTransform.js Component received.");  
           if(transform instanceof Array ) {   
                if(transform.length === 9)  
                    setTransformData(transform); 
           }
        
    }, [transform]);

    function handleTextChange(event){
        console.log(event.target.name)
        
        switch(event.target.name) {
            case 'posX':
                setPosX(event.target.value)
              break;
            case 'posY':
                setPosY(event.target.value)
              break;
            case 'posZ':
                setPosZ(event.target.value)
              break;
            case 'rotX':
                setRotX(event.target.value)
              break;
            case 'rotY':
                setRotY(event.target.value)
              break;
            case 'rotZ':
                setRotZ(event.target.value)
              break;
            case 'scaleX':
                setScaleX(event.target.value)
              break;
            case 'scaleY':
                setScaleY(event.target.value)
              break;
            case 'scaleZ':
                setScaleZ(event.target.value)
              break;  
            default:
              break;
          }
        
    }

    function setTransformData(data){
       
        setPosX(data[0].toFixed(2))
        setPosY(data[1].toFixed(2))
        setPosZ(data[2].toFixed(2))

        setRotX(data[3].toFixed(2))
        setRotY(data[4].toFixed(2))
        setRotZ(data[5].toFixed(2))

        setScaleX(data[6].toFixed(2))
        setScaleY(data[7].toFixed(2))
        setScaleZ(data[8].toFixed(2))
    }

    // Selects all in the text input field for easy copy and paste
    const handleFocus = (event) => {
      event.target.select();
    }

    return (
        <>
        <Row>
          <Col></Col>
          <Col>X</Col>
          <Col>Y</Col>
          <Col>Z</Col>
        </Row>
                <Row>
                    <Col style={{backgroundColor: "lightgrey",borderRadius: "5px", padding: "2px", margin: "2px"}}>
                    <Form.Label>Position</Form.Label>
                    <Form.Label>Rotation</Form.Label>
                    <Form.Label>Scale</Form.Label>
                    </Col>
                    <Col style={{backgroundColor: "red",borderRadius: "5px", padding: "2px", margin: "2px"}}>
                    <Form.Control size="sm" name="posX" type="text" placeholder="X" value={ posX } onFocus={handleFocus} onChange={e => handleTextChange(e)} onKeyUp={ e => {if(e.key === ','){e.target.value = e.target.value.slice(0,-1) + '.'}; if(isFinite(parseFloat(posX))) { unityContext.send("LevelManager", "MoveSelectedObjectX", parseFloat(posX))}}} style={{ boxShadow: "none"}}/>
                    <Form.Control size="sm" name="rotX" type="text" placeholder="X" value={ rotX } onFocus={handleFocus} onChange={e => handleTextChange(e)} onKeyUp={ e => {if(e.key === ','){e.target.value = e.target.value.slice(0,-1) + '.'}; if(isFinite(parseFloat(rotX))){ unityContext.send("LevelManager", "RotateSelectedObjectX", parseFloat(rotX))}}} style={{ boxShadow: "none"}}/>  
                    <Form.Control size="sm" name="scaleX" type="text" placeholder="X" value={ scaleX } onFocus={handleFocus} onChange={e => handleTextChange(e)} onKeyUp={ e => {if(e.key === ','){e.target.value = e.target.value.slice(0,-1) + '.'}; if(isFinite(parseFloat(scaleX))){ unityContext.send("LevelManager", "ScaleSelectedObjectX", parseFloat(scaleX))}}} style={{ boxShadow: "none"}}/>
                    </Col>
                    <Col style={{backgroundColor: "green",borderRadius: "5px", padding: "2px", margin: "2px"}}>
                    <Form.Control size="sm" name="posY" type="text" placeholder="Y" value={ posY } onFocus={handleFocus} onChange={e => handleTextChange(e)} onKeyUp={ e => {if(e.key === ','){e.target.value = e.target.value.slice(0,-1) + '.'}; if(isFinite(parseFloat(posY))){ unityContext.send("LevelManager", "MoveSelectedObjectY", parseFloat(posY))}}} style={{ boxShadow: "none"}}/>
                    <Form.Control size="sm" name="rotY" type="text" placeholder="Y" value={ rotY } onFocus={handleFocus} onChange={e => handleTextChange(e)} onKeyUp={ e => {if(e.key === ','){e.target.value = e.target.value.slice(0,-1) + '.'}; if(isFinite(parseFloat(rotY))){ unityContext.send("LevelManager", "RotateSelectedObjectY", parseFloat(rotY))}}} style={{ boxShadow: "none"}}/>  
                    <Form.Control size="sm" name="scaleY" type="text" placeholder="Y" value={ scaleY } onFocus={handleFocus} onChange={e => handleTextChange(e)} onKeyUp={ e => {if(e.key === ','){e.target.value = e.target.value.slice(0,-1) + '.'}; if(isFinite(parseFloat(scaleY))){ unityContext.send("LevelManager", "ScaleSelectedObjectY", parseFloat(scaleY))}}} style={{ boxShadow: "none"}}/>
                    </Col>
                    <Col style={{backgroundColor: "blue",borderRadius: "5px", padding: "2px", margin: "2px"}}>
                    <Form.Control size="sm" name="posZ" type="text" placeholder="Z" value={ posZ } onFocus={handleFocus} onChange={e => handleTextChange(e)} onKeyUp={ e => {if(e.key === ','){e.target.value = e.target.value.slice(0,-1) + '.'}; if(isFinite(parseFloat(posZ))){ unityContext.send("LevelManager", "MoveSelectedObjectZ", parseFloat(posZ))}}} style={{ boxShadow: "none"}}/>  
                    <Form.Control size="sm" name="rotZ" type="text" placeholder="Z" value={ rotZ } onFocus={handleFocus} onChange={e => handleTextChange(e)} onKeyUp={ e => {if(e.key === ','){e.target.value = e.target.value.slice(0,-1) + '.'}; if(isFinite(parseFloat(rotZ))){ unityContext.send("LevelManager", "RotateSelectedObjectZ", parseFloat(rotZ))}}} style={{ boxShadow: "none"}}/>  
                    <Form.Control size="sm" name="scaleZ" type="text" placeholder="Z" value={ scaleZ } onFocus={handleFocus} onChange={e => handleTextChange(e)} onKeyUp={ e => {if(e.key === ','){e.target.value = e.target.value.slice(0,-1) + '.'}; if(isFinite(parseFloat(scaleZ))){ unityContext.send("LevelManager", "ScaleSelectedObjectZ", parseFloat(scaleZ))}}} style={{ boxShadow: "none"}}/>
                    </Col>
                  </Row>
        </>
    );
}

export default InspectorTransform;