import React from 'react'
import { Card, Button, ToggleButton, ButtonGroup } from 'react-bootstrap'
import { useState } from 'react';


function LaunchMenu({ unityContext, LobbyRoomPickerShow, setLobbyRoomPickerShow }) {

    const [radioValue, setRadioValue] = useState('1');

    const showGrid = [
        { name: 'On', value: '1' },
        { name: 'Off', value: '2' },
    ];

    function handleSelection(value) {
        setRadioValue(value)

        if (value === '1') {
            unityContext.send("LevelManager", "SetGridVisibilty", "true");
        } else if (value === '2') {
            unityContext.send("LevelManager", "SetGridVisibilty", "false");
        }
    }
    function handleButton() {
        setLobbyRoomPickerShow(true);
    }

    return (
        <div>
            These Components are in development:
            <Card style={{ width: '260px' }}>
                <Card.Body>
                    <Card.Title>Settings</Card.Title>
                    <Card.Text style={{ fontSize: '10pt' }}>
                        <div style={{display: "flex", flexFlow: "column", rowGap: "1rem"}}>
                            <ButtonGroup size="sm" style={{ width: '100%' }}>
                                <Button variant="outline-dark" disabled style={{width: "50%"}} >World Grid</Button>
                                {showGrid.map((radio, idx) => (
                                    <ToggleButton
                                        key={idx}
                                        id={`radio-${idx}`}
                                        type='radio'
                                        value={radio.value}
                                        variant={'outline-primary'}
                                        checked={radioValue === radio.value}
                                        onChange={(e) => handleSelection(e.currentTarget.value)}
                                        style={{ boxShadow: "none" }
                                        }>

                                        {radio.name}

                                    </ToggleButton>
                                ))}
                            </ButtonGroup>
                            <ButtonGroup size="sm" >
                                <Button variant="outline-dark" disabled style={{width: "50%"}}>Select Lobby Rooms</Button>
                                <Button variant="outline-primary" onClick={handleButton} >Open Menu</Button>
                            </ButtonGroup>
                        </div>
                    </Card.Text>
                </Card.Body>
            </Card>
        </div>
    )
}

export default LaunchMenu

