import "./App.css";
import React, { useState, useEffect } from "react";
import Unity, { UnityContext } from "react-unity-webgl";

import TopNav from "./components/TopNav"
import MainNav from "./components/MainNav"
import { Stack } from "react-bootstrap";
import Inspector from "./components/Inspector/Inspector";
import PopUp from "./components/PopUp";
import PopUpGDTF from "./components/PopUpGDTF";
import LoadingScreen from "./components/LoadingScreen";
import LobbyRoomPicker from "./LobbyRoomPicker";

const unityContext = new UnityContext({
	loaderUrl: process.env.PUBLIC_URL + "/appweb/Build/appweb.loader.js",
	dataUrl: process.env.PUBLIC_URL + "/appweb/Build/appweb.data",
	frameworkUrl: process.env.PUBLIC_URL + "/appweb/Build/appweb.framework.js",
	codeUrl: process.env.PUBLIC_URL + "/appweb/Build/appweb.wasm",
	webglContextAttributes: {
		preserveDrawingBuffer: true,
	},
});

export const IdbContext = React.createContext(); // ToDo: Shift the Context to a seperate provider.js

function App() {
	const [currentRoomId, setCurrentRoomId] = useState("");
	const [showPopUp,setPopUpState] = useState(false);
	const [showGDTFPopUp,setGDTFPopUpState] = useState(false);
	const [showRoomPickerState,setRoomPickerState] = useState(false);



	const RoomArray = ["a2587198-a334-462b-9acc-d4d1275edfbe","2613626a-4a79-497c-b045-34ea3361ae70","2bb49117-9284-4474-9ec2-47c0fc1aa1cc","0264419d-2397-4d51-a1ec-9dc065d6e6a3"];
	
	//prevent middle MouseClick:
	document.body.onmousedown = function(e) { if (e.button === 1) return false; }

	useEffect(() =>{
		document.addEventListener('mousedown', setWebGLKeyboardCapture)

		return () => {document.removeEventListener('mousedown', setWebGLKeyboardCapture)}
	},[])
	
	
	const setWebGLKeyboardCapture = (e) => {
		//console.log(e.target.id)
		if(e.target.id === "unity-canvas-1"){
			unityContext.send("LevelManager", "SetKeyboardCapture", "1")	
		} else {
			unityContext.send("LevelManager", "SetKeyboardCapture", "0")			
		}
	}

	return (
		<>
			<PopUp unityContext = {unityContext} showPopUpState={showPopUp} setShowPopUpState={setPopUpState}/>
			<PopUpGDTF unityContext={unityContext} showPopUpState={showGDTFPopUp} setShowPopUpState={setGDTFPopUpState}/>
			<LobbyRoomPicker showRoomPickerState={showRoomPickerState} setRoomPickerState={setRoomPickerState} currentRoomID={currentRoomId} setCurrentRoomID={setCurrentRoomId} unityContext={unityContext}/>

			<LoadingScreen unityContext={unityContext}/>

			<IdbContext.Provider value={{currentRoomId, setCurrentRoomId}}>
				<TopNav unityContext={unityContext} RoomArray={RoomArray}/>
			</IdbContext.Provider>

			<Stack direction="horizontal" gap={3} >
			<div className="" style={{minWidth: 330, width: '20vw'}} >
				<IdbContext.Provider value={{currentRoomId, setCurrentRoomId}}>
					<MainNav unityContext={unityContext} RoomArray={RoomArray} showRoomPickerState={showRoomPickerState} setRoomPickerState={setRoomPickerState}></MainNav>
				</IdbContext.Provider>
			</div>
			<div className="bg-light border" style={{height: 'calc(100vh - 5rem)'}} >
				<Unity className="Unity" unityContext={unityContext} />
			</div>
			<div className="bg-light border ms-auto" style={{minWidth: 280, width:'20vw', height: 'calc(100vh - 5rem)'}}>
				<Inspector unityContext={unityContext} tabIndex={1} setPopUpState={setPopUpState}></Inspector>
			</div>
			
			</Stack>
		</>
	);
}

export default App;
