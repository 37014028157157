import { Row, Col, Form } from "react-bootstrap";
import { useState, useEffect } from "react";
import ColorPickerButton from "./ColorPickerButton";
import InspectorDropdown from "./InspectorDropdown";

function InspectorPrefab({ itemcustomArgs,unityContext })  {
    
    // key, value structure used here
    const[prefabData, setPrefabData] = useState({});

    useEffect(function () {
        
            console.log("InspectorPrefab.js Args received.");   
            
            setPrefabData({})

            const responseString =  itemcustomArgs.slice(0,-1); // remove last colon
            const kvPairArray =  responseString.split(";");
            
            kvPairArray.forEach(element => {
                const kvItem = element.split(",");
                const optionsArray = kvItem[3].split("|");
                var Args = [kvItem[1],kvItem[2],optionsArray];
                console.log(Args)
                setPrefabData(prefabData => ({...prefabData, [kvItem[0]]: Args}))

            
                
            });
            
        
        
    }, [itemcustomArgs]);


    function handleTextChange(event){
        const data = prefabData[event.target.name];
        data[0] = event.target.value;
        setPrefabData(prefabData => ({...prefabData, [event.target.name]: data}))
    }


    function handleSubmit(event){        
        const kvPairString = event.target.name + "," + event.target.value 
        unityContext.send("LevelManager", "ChangeCustomArg", kvPairString)
    }
    function handleDropDownSubmit(value,name){        
        console.log(prefabData);
        const data = prefabData[name];
        data[0] = value;
        setPrefabData(prefabData => ({...prefabData, [name]: data}));
        console.log(prefabData);
        const kvPairString = name + "," + value;
        unityContext.send("LevelManager", "ChangeCustomArg", kvPairString)
    }

    function handleColorSubmit(color) {
        unityContext.send("LevelManager", "ChangeCustomArg", "color,"+color.hex)
    }


  
    // Selects all in the text input field for easy copy and paste
    const handleFocus = (event) => {
      event.target.select();
    }

    return (
        <>
         { Object.entries(prefabData).map((item,index) => (
             <>
            <Row>
                <Col md="auto" className="text-truncate" style={{ width: "80px"}}>
                    <Form.Label>{item[0]}</Form.Label>
                </Col>
                <Col style = {{ display: "block", width:"100%"}}>

                
                    {item[1][1] == "Color" ?
                    <ColorPickerButton color ={item[1][0]} colorChange = {handleColorSubmit} style = {{width:"100%"}}/>
                    :item[1][1] == "Slider" ?
                    <Form.Control name={item[0]} size="sm" type="text" placeholder="[Slider WIP]" value={item[1][0]} onFocus={handleFocus} onChange={e => handleTextChange(e)} onKeyUp={ e => {if( e.key === 'Enter' ){ handleSubmit(e) }}} style={{ boxShadow: "none"}}/>
                    :item[1][1] == "Bool" ?
                    <Form.Control name={item[0]} size="sm" type="text" placeholder="[BOOLENAN WIP]" value={item[1][0]} onFocus={handleFocus} onChange={e => handleTextChange(e)} onKeyUp={ e => {if( e.key === 'Enter' ){ handleSubmit(e) }}} style={{ boxShadow: "none"}}/>
                    :item[1][1] == "Dropdown" ?
                    <InspectorDropdown value = {item[1][2][item[1][0]]} onChange = {e =>{handleDropDownSubmit(item[1][2].indexOf(e),item[0]);}} options = {item[1][2]}></InspectorDropdown>
                    :
                    <Form.Control name={item[0]} size="sm" type="text" placeholder="[String]" value={item[1][0]} onFocus={handleFocus} onChange={e => handleTextChange(e)} onKeyUp={ e => {if( e.key === 'Enter' ){ handleSubmit(e) }}} style={{ boxShadow: "none"}}/>
                    }
                </Col>
            </Row>
            
            
         
            </>
         ))}
        </>
    );
}

export default InspectorPrefab;