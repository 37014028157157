import React from 'react'
import { Card } from 'react-bootstrap'


const ExampleRoomCard = ({roomID,unityContext}) => {

    function requestLoadRoom(id){
        console.log(id)
        unityContext.send("LevelManager", "loadRoom", id); //currentRoomId ??? 
    }

    return (
        <Card style={{ width: '260px', marginTop: '4px', marginLeft: '1px'  }}>
                <Card.Img variant="top" src={"https://xrevent-creator.de/upload/"+roomID+"/preview.jpg"} type="button" onClick={() => requestLoadRoom(roomID)}/>
                <Card.Body>
                    <Card.Title>Room A</Card.Title>
                    <Card.Text style={{fontSize: '10pt'}}>
                    The XRevent ID for this room is: {roomID}
                    </Card.Text>
                    
                </Card.Body>
            </Card>
    )
}

function SettingsMenu({unityContext,RoomArray}){


// Unity request functions
function requestLoadRoom(id){
    console.log(id)
    unityContext.send("LevelManager", "loadRoom", id); //currentRoomId ??? 
}





    return (
        <div style={{  height: 'calc(100vh - 4rem)', overflowY: "scroll", overflowX: "hidden" }} >
            
        
            <Card style={{ width: '260px', marginTop: '2px', marginLeft: '1px' }}>
            <Card.Body>
                <Card.Title>Description</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">XRevent Creator</Card.Subtitle>
                <Card.Text style={{fontSize: '10pt'}}>
                    The XRevent Creator is intended to help users of the XRevent platform to build a virtual venue location.
                    Rooms built with this tool can be dynamically loaded by the client software. 
                </Card.Text>
                <p>
                    Download Demo Clients <br/>
                    <Card.Link href="../download/Quest/xrevent.apk">Occulus Quest</Card.Link>
                    <Card.Link href="../download/Win/XRevent_Win.zip">Win</Card.Link>
                    <Card.Link href="../download/Mac/AppMac.app.zip">Mac</Card.Link>
                </p>
                <p>
                    XRevent Browser Preview<br/>
                    <Card.Link href="../play">WebGL</Card.Link>
                </p>
                <Card.Text style={{fontSize: '10pt'}}>
                    We have prepared these six rooms for you to edit. Feel free to modify them.
                </Card.Text>
            </Card.Body>
            </Card>

            {RoomArray.map ((roomID,i) =>{
                return (<ExampleRoomCard roomID={roomID} unityContext={unityContext}></ExampleRoomCard>)
            })}

        </div>
    )
}

export default SettingsMenu

