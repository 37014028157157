'use strict'

import React, { Component } from 'react'
import { SketchPicker } from 'react-color';

function hexToRgb(hex) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

export class ColorPickerButton extends Component {

  constructor() {
    super()
    this.state = {
      displayColorPicker: false,
      color: {
        r: '100',
        g: '112',
        b: '19',
        a: '1',
      }
    }
  }
  componentDidMount() {
    var colorConverted = hexToRgb(this.props.color);
    this.setState({ color: { r: colorConverted.r, g: colorConverted.g, b: colorConverted.b, a: '1' } });
  }



  handleClick = () => {
    this.setState({ displayColorPicker: true});
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false })
  };

  handleChange = (color) => {
    this.setState({ color: color.rgb })
    this.props.colorChange(color)
  };

  handleChangeComplete = (color) => {
    this.setState({ color: color.rgb })
    this.props.colorChange(color)
  };



  render() {
    const popover = {
      position: 'absolute',
      zIndex: '2',
      left: '30px',
    }
    const cover = {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px'
    }
    return (
      <div>
        <div style={{ padding: '5px', background: '#fff', borderRadius: '1px', boxShadow: '0 0 0 1px rgba(0,0,0,.1)', display: 'inline-block', cursor: 'pointer' }} onClick={this.handleClick}>
          <div style={{ width: '150px', height: '15px', borderRadius: '2px', background: `rgba(${this.state.color.r}, ${this.state.color.g}, ${this.state.color.b}, ${this.state.color.a})` }} />
        </div>

        {this.state.displayColorPicker ?
          <div style={popover}>
            <div style={cover} onClick={this.handleClose} />
            <SketchPicker color={this.state.color} onChange={this.handleChange} onChangeComplete={this.handleChangeComplete} />
          </div>
          : null
        }
      </div>
    )
  }
}

export default ColorPickerButton