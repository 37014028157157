import Item from "./Item"

const Items = ({fileNames, unityContext,RoomArray}) => {
    return (
        <div>
           { fileNames.map((item) => (
                <Item itemName={item.toString()} unityContext={unityContext} RoomArray={RoomArray}></Item>
            ))}
        </div>
    )
}

export default Items
