import Modal from "react-bootstrap/Modal";
import Button from 'react-bootstrap/Button';
import React, { useState, useEffect } from "react";


function PopUpGDTF({ unityContext, showPopUpState, setShowPopUpState }) {

  const handleButtonConfirm = () => {
    console.log("modes: " + modes + " DMXMode: "+ DmxMode);
    const parameter = FileName + "," + modes.indexOf(DmxMode) + "," + DMXUniverse +"," + StartChannel;
    console.log("parameter: " + parameter);
    unityContext.send("LevelManager", "SpawnGDTFfromJS",parameter);
    console.log("parameter send");
    setShowPopUpState(false);
  }
  const handleButtonCancel = () => {
    /* unityContext.send("LevelManager", "PopUpFeedback",1) */
    setShowPopUpState(false);
  }

  //Text Values
  const [PopUpTitle, setPopUpTitle] = useState("Default Title");
  const [PopUpBody, setPopUpBody] = useState([]);

  //GDTF Values:
  const [FileName,setFileName] = useState("");
  const [DmxMode,setDmxMode] = useState("");
  const [StartChannel,setStartChannel] = useState("0");
  const [DMXUniverse,setDMXUniverse] = useState("0");
  const [modes, setModes] = useState([]);
  

  useEffect(function () {
    unityContext.on("PopUpGDTFSelector", function (FileName,TitelString, ModesString, StartUniverseRecommendInt, StartChannelRecommendInt) {
      console.log("recieved 'PopUpGDTFSelector' From Unity");
      console.log(TitelString + " : " + ModesString);
      setFileName(FileName);
      setPopUpTitle(TitelString);
      var ms = String(ModesString).split(",");
      setModes(ms);
      setPopUpBody(ms);
      setShowPopUpState(true);
      setDmxMode(ms[0]);
      setDMXUniverse(StartUniverseRecommendInt);
    });
  }, []);

  function handleModeChange(e){
    setDmxMode(e.target.value)
  };

  const onChangeHandlerChannel = event => {
    setStartChannel(event.target.value);
 };
 const onChangeHandlerUniverse = event => {
  setDMXUniverse(event.target.value);
};

  return (
    <>

      <Modal
        show={showPopUpState}
        onHide={handleButtonCancel}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{PopUpTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <select value={DmxMode} onChange={handleModeChange}>
            {PopUpBody.map((mode, i) => {
              return <option id={i} >{mode}</option>
            })}
          </select>
          <p>Universe:</p>
          <input type="text" name="universe" onChange={onChangeHandlerUniverse} value={DMXUniverse}/>
          <p>Channel:</p>
          <input type="text" name="Channel" onChange={onChangeHandlerChannel} value={StartChannel}/>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleButtonConfirm}>Spawn Fixture</Button>
          <Button variant="secondary" onClick={handleButtonCancel}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PopUpGDTF