import { Card, Col, OverlayTrigger, Tooltip } from "react-bootstrap";

export default function DefaultPrefab({ unityContext, type }) {
    function SpawnItemEvent() {
        unityContext.send("LevelManager", "SpawnPrefab", type);

    }

    return (
        <Col style={{padding: "0.5rem 3%"}}>
            <Card border="gray" style={{width: "94%", maxWidth: "120px", minHeight: "100%", marginBottom: "0rem"}} type="button" onClick={SpawnItemEvent}>
                { type === "Point Light" &&
                <OverlayTrigger	placement="right" delay={{ show: 100, hide: 250 }} overlay={ <Tooltip>Point Lights emit light in all directions from their position in the world.</Tooltip>}>
                    <Card.Img className="card-img-top"  src="res/icons/icon_point_light.png" style={{maxHeight: "100px"}} altText=""></Card.Img>
                </OverlayTrigger>
                } 
                { type === "Directional Light" &&
                <OverlayTrigger	placement="right" delay={{ show: 100, hide: 250 }} overlay={ <Tooltip>Light rays emitted from Directional Lights are parallel to one another and do not diverge like those from other light types. As a result, shadows cast by Directional Lights look the same, regardless of their position relative to the source.</Tooltip>}>
                    <Card.Img className="card-img-top"  src="res/icons/icon_directional_light.png" style={{maxHeight: "100px"}} altText=""></Card.Img>
                </OverlayTrigger>
                }
                { type === "Cube" &&
                <Card.Img className="card-img-top"  src="res/icons/icon_cube.png" style={{maxHeight: "100px"}} altText=""></Card.Img>
                }  
                { type === "Sphere" &&
                <Card.Img className="card-img-top"  src="res/icons/icon_sphere.png" style={{maxHeight: "100px"}} altText=""></Card.Img>
                }
                { type === "Cylinder" &&
                <Card.Img className="card-img-top"  src="res/icons/icon_cylinder.png" style={{maxHeight: "100px"}} altText=""></Card.Img>
                }  
                { type === "Plane" &&
                <Card.Img className="card-img-top"  src="res/icons/icon_plane.png" style={{maxHeight: "100px"}} altText=""></Card.Img>
                }  
                { type === "Entrance" &&
                <OverlayTrigger	placement="right" delay={{ show: 100, hide: 250 }} overlay={ <Tooltip>Use this to define where visitors will enter the venue.</Tooltip>}>
                    <Card.Img className="card-img-top"  src="res/icons/icon_event_entrance.png" style={{maxHeight: "100px"}} altText=""></Card.Img>
                </OverlayTrigger>
                }
                { type === "Exit to Lobby" &&
                <OverlayTrigger	placement="right" delay={{ show: 100, hide: 250 }} overlay={ <Tooltip>This is where visitors can exit your venue back to the XRevent Lobby.</Tooltip>}>
                    <Card.Img className="card-img-top"  src="res/icons/icon_event_exit.png" style={{maxHeight: "100px"}} altText=""></Card.Img>
                </OverlayTrigger>
                }
                { type === "VideoWall" &&
                <OverlayTrigger	placement="right" delay={{ show: 100, hide: 250 }} overlay={ <Tooltip>Used to diplay a Http live stream (HLS).</Tooltip>}>
                    <Card.Img className="card-img-top"  src="res/icons/icon_videowall.png" style={{maxHeight: "100px"}} altText=""></Card.Img>
                </OverlayTrigger>
                }
                { type === "Speaker" &&
                <OverlayTrigger	placement="right" delay={{ show: 100, hide: 250 }} overlay={ <Tooltip>Used to play an audio live stream (.mp3).</Tooltip>}>
                    <Card.Img className="card-img-top"  src="res/icons/icon_speaker.png" style={{maxHeight: "100px"}} altText=""></Card.Img>
                </OverlayTrigger>
                }
                { type === "Truss" &&
                <OverlayTrigger	placement="right" delay={{ show: 100, hide: 250 }} overlay={ <Tooltip>Select different length in the properties.</Tooltip>}>
                    <Card.Img className="card-img-top"  src="res/icons/icon_traverse.png" style={{maxHeight: "100px", minWidth: "90px"}} altText=""></Card.Img>
                </OverlayTrigger>
                }
                { type === "T-piece" &&
                <OverlayTrigger	placement="right" delay={{ show: 100, hide: 250 }} overlay={ <Tooltip>Select different length in the properties.</Tooltip>}>
                    <Card.Img className="card-img-top"  src="res/icons/icon_traverse.png" style={{maxHeight: "100px"}} altText=""></Card.Img>
                </OverlayTrigger>
                }
                { type === "Base Plate" &&
                <OverlayTrigger	placement="right" delay={{ show: 100, hide: 250 }} overlay={ <Tooltip>Select different length in the properties.</Tooltip>}>
                    <Card.Img className="card-img-top"  src="res/icons/icon_traverse.png" style={{maxHeight: "100px"}} altText=""></Card.Img>
                </OverlayTrigger>
                }
                { type === "Billboard" &&
                <OverlayTrigger	placement="right" delay={{ show: 100, hide: 250 }} overlay={ <Tooltip>Used to diplay a picture from the web.</Tooltip>}>
                    <Card.Img className="card-img-top"  src="res/icons/icon_billboard.png" style={{maxHeight: "100px"}} altText=""></Card.Img>
                </OverlayTrigger>
                }
                { type === "Banner" &&
                <OverlayTrigger	placement="right" delay={{ show: 100, hide: 250 }} overlay={ <Tooltip>Used to diplay a picture from the web</Tooltip>}>
                    <Card.Img className="card-img-top"  src="res/icons/icon_banner.png" style={{maxHeight: "100px"}} altText=""></Card.Img>
                </OverlayTrigger>
                }
                { type === "Curtain" &&
                <OverlayTrigger	placement="right" delay={{ show: 100, hide: 250 }} overlay={ <Tooltip>Scale the curtain to get different sizes.</Tooltip>}>
                    <Card.Img className="card-img-top"  src="res/icons/icon_curtain.png" style={{maxHeight: "100px"}} altText=""></Card.Img>
                </OverlayTrigger>
                }
                { type === "VideoStreamLiveKit" &&
                <OverlayTrigger	placement="right" delay={{ show: 100, hide: 250 }} overlay={ <Tooltip>Ähhhhhhhhhhhhhh</Tooltip>}>
                    <Card.Img className="card-img-top"  src="res/icons/logo.png" style={{maxHeight: "100px"}} altText=""></Card.Img>
                </OverlayTrigger>
                }
                <Card.Body style={{padding: "0rem 0.5rem"}}>{type}</Card.Body>
            </Card>
        </Col>
    )
}
