import { Card, Col} from "react-bootstrap";

export default function DefaultPrefab({ unityContext, filename, manufacturer, itemname }) {
    function SpawnItemEvent() {
        unityContext.send("LevelManager", "LoadGDTF", filename);
    }

    return (
        <Col style={{padding: "0.5rem 3%"}}>
            <Card border="gray" style={{width: "94%", minHeight: "100%", marginBottom: "0rem"}} type="button" onClick={SpawnItemEvent}>
                <Card.Body style={{padding: "0rem 0.5rem"}}><span style={{fontWeight: "bold"}}>{itemname}</span><br/>{filename}</Card.Body>
            </Card>
        </Col>
    )
}
