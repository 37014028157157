import React, { useState, useEffect } from "react";
import { Accordion,Card, Row, Col,Carousel } from "react-bootstrap";
import InspectorTransform from "./InspectorTransform";
import InspectorPrefab from "./InspectorPrefab";

function Inspector({ unityContext ,setPopUpState}) {

    const [isItemSelected, setIsItemSelected] = useState(false);
    const [itemName, setItemName] = useState("");
    const [itemTransform, setItemTransform] = useState([]);
    const [itemCustomArgs, setItemCustomArgs] = useState("");


    useEffect(function () {
        unityContext.on("ItemInfo", function (itemName, itemID, itemdata) { // Do NOT register the same .on message twice in another component! It it becomes flakey!
            console.log("Inspector.js Component received.");                  
            if (itemName === "" && itemID === 0) { 
                setIsItemSelected(false)        
                setItemName("")
            } else {
                setIsItemSelected(true)
                setItemName(itemName)
            }
            setItemTransform(itemdata);
            setItemCustomArgs("");
        });
        

    }, [unityContext]);

    useEffect(function() {
        unityContext.on("ItemInfoCustomArgs", function (customArgList){
            setItemCustomArgs(customArgList);
        });
    }, [unityContext]);
        
    
  
    
    // SendMethod to trigger Unity WebGL to delete the selected model
    function DeleteItemEvent() {
        unityContext.send("LevelManager", "DeleteSelectedItem");
        console.log("Item Delete Requested");
    }
    function DuplicateItemEvent() {
        unityContext.send("LevelManager","DuplicateSelectedItem");
        console.log("Item Duplicate Requested");
    }
    
    return (
        <>
            {isItemSelected &&
           
                <Accordion defaultActiveKey="">
                    <Card>
                        <Card.Header>Item Inspector</Card.Header>
                        <Card.Body>
                            <Row>
                                <Col style={{fontWeight: "lighter"}}>Item Name</Col>
                            </Row>
                            <Row> 
                                <Col className="text-truncate" style={{ display: "block" }}>
                                {itemName}
                                </Col>
                            </Row>  
                            <Row>
                                <Col>  
                                      
                                    <button style={{ marginTop: "10px", width: "100%" }} className="btn btn-outline-secondary" onClick={DeleteItemEvent}>Remove Item</button>
                                    <button style={{ marginTop: "10px", width: "100%" }} className="btn btn-outline-secondary" onClick={DuplicateItemEvent}>Duplicate Item</button>
                                   
                                </Col>    
                            </Row>  
                        </Card.Body>  
                    </Card>
                    <Card>
                        <Card.Header>Transform</Card.Header>
                        <Card.Body >
                            <InspectorTransform unityContext={unityContext} transform={itemTransform} />
                        </Card.Body>
                    </Card>
                    {itemCustomArgs &&
                    <Card>
                        <Card.Header>
                            Prefab Properties
                        </Card.Header>
                        
                            <Card.Body>
                                <InspectorPrefab itemcustomArgs ={itemCustomArgs} unityContext={unityContext} />
                            </Card.Body>
                        
                    </Card>
                    }
                    
                </Accordion>
            }
            {!isItemSelected  &&
                 <div>
                    {/*<Card style={{ width: '277px' }}>*/}
                    {/*<Card.Body>*/}
                    {/*    <Card.Title>Controls</Card.Title>*/}
                    {/*    <Card.Subtitle className="mb-2 text-muted">XRevent Creator</Card.Subtitle>*/}
                    {/*    <Card.Text style={{fontSize: '10pt'}}>*/}
                    {/*        <Image src="res/icons/Zoom.png"/>*/}
                    {/*        <Image src="res/icons/Rotate.png"/> */}
                    {/*        <Image src="res/icons/Translate.png"/> */}
                    {/*    </Card.Text>*/}
                    {/*</Card.Body>*/}
                    {/*</Card>*/}


                    <Card style={{ width: '277px' }}>
                    <Card.Body>
                        <Card.Title>Funded & Sponsored by</Card.Title>
                        <Card.Subtitle className="mb-2 text-muted"></Card.Subtitle>
                        <Card.Text style={{fontSize: '10pt', height: "125px", overflow: "hidden"}}>

                            <Carousel indicators={false} controls={false} style={{ marginTop: "20px"}}>
                            <Carousel.Item>
                                <img
                                className="d-block"
                                src={"res/logos/BMBF_logo.svg"}
                                alt="BMBF Logo"
                                />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                className="d-block w-100"
                                src={"res/logos/Open_Knowledge_Foundation_Deutschland_Logo.svg"}
                                alt="OKF Logo"
                                />

                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                className="d-block "
                                src={"res/logos/Prototype2_logo.svg"}
                                alt="Prototype Fund Logo"
                                />

                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                className="d-block"
                                src={"res/logos/haw-hamburg_logo.svg"}
                                alt="HAW Hamburg Logo"
                                /> 
                            </Carousel.Item>
                            </Carousel>
                            
                        </Card.Text>
                    </Card.Body>
                    </Card>
                    <Card style={{ width: '277px' }}>
                    <Card.Body>
                        <Card.Title>Changelog:</Card.Title>
                        <Card.Subtitle className="mb-2 text-muted"></Card.Subtitle>
                        <Card.Text style={{fontSize: '10pt', overflow: "hidden"}}>
                            <h5>0.16.2-react</h5>
                            <h6>React:</h6>
                            <ul>
                                <li> Disabled the Tutorial as it is wrong.</li>
                            </ul>


                            <h5>0.16.1-react / v0.15.9LiveKitDev-unity</h5>
                            <h6> Unity:</h6>
                            <ul>
                                <li> Added the LiveKitPrefab</li>
                            </ul>
                            <h6>React:</h6>
                            <ul>
                                <li> Buttons "setToCurrentRoom" and "Load Room" in the LobbyRoomSelector now work. <strong> Dont forget to save!</strong></li>
                            </ul>
                            
                        </Card.Text>
                    </Card.Body>
                    </Card>
                 </div>
            }   
        </>
    );
}

export default Inspector;