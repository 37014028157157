import React from 'react';
import { Dropdown } from "react-bootstrap";




function InspectorDropdown({value,onChange,options}) {
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
          href=""
          ref={ref}
          onClick={(e) => {
            e.preventDefault();
            onClick(e);
          }}
          className="border text-reset text-center" style={{ display:"inline-block", background: "white", textDecoration: "none", boxShadow: "none", width:"100%" , marginBottom: "3px" }}
        >
          {children}
         
        </a>
      ));
      
      // forwardRef again here!
      // Dropdown needs access to the DOM of the Menu to measure it
      const CustomMenu = React.forwardRef(
        ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
                
          return (
            <div
              ref={ref}
              style={{marginBottom: "5px"}}
              className={className}
              aria-labelledby={labeledBy}
            >
             
                <ul className="list-unstyled text-center" 
                onClick={(e) => {
                onChange(e.target.getAttribute('value'));  
                e.preventDefault();
                }}
               
                
                >

                {React.Children.toArray(children)}
              </ul>
            </div>
          );
        },
      );

  return (
    <>
        <Dropdown>
                <Dropdown.Toggle as={CustomToggle} >
                {value}
                </Dropdown.Toggle>
                    <Dropdown.Menu as={CustomMenu} >
                        { options.map((item) => (
                            <Dropdown.Item value={item.toString()}>{item.toString()}</Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
        </Dropdown>
            
        </>
  )
}

export default InspectorDropdown