import Nav from "react-bootstrap/Nav";
import Stack from "react-bootstrap/Stack";
import { Badge } from "react-bootstrap";
import { useState, useEffect } from "react";

import AccMenu from "./AccMenu";
import SettingsMenu from "./SettingsMenu";
import PretixMenu from "./Pretix/PretixMenu";
import LaunchMenu from "./LaunchMenu";

const MainNav = ({ unityContext,RoomArray,showRoomPickerState,setRoomPickerState}) => {
    const [composeIsShown, setComposeIsShown] = useState(true)
    const [settingsIsShown, setSettingsIsShown] = useState(false)
    const [pretixIsShown, setPretixIsShown] = useState(false)
    const [launchIsShown, setLaunchIsShown] = useState(false)
    



    const menuHandler = (eventKey) => {
        hideAll()
        
        switch(eventKey) {
            case 'compose':
                setComposeIsShown(true)
              break;
            case 'settings':
                setSettingsIsShown(true)
                break;
            case 'pretix':
                setPretixIsShown(true)
                break;
            case 'launch':
                setLaunchIsShown(true)
                break;
            default:
              break;
          }
    }
    // Switch to pretix menu if param exists
    useEffect(function () {
        new URLSearchParams(window.location.search).get("code") != null || new URLSearchParams(window.location.search).get("access_token") ? menuHandler('pretix') : console.log("No menu preference set.")
    }, [menuHandler]);

    function hideAll(){
        setSettingsIsShown(false)
        setComposeIsShown(false)
        setPretixIsShown(false)
        setLaunchIsShown(false)
    }


    return (
        <>
            <Stack direction="horizontal" gap={0} >
                <div>
                    <Nav className="bg-light border flex-column" style={{height: 'calc(100vh - 5rem)'}} defaultActiveKey="compose" onSelect={menuHandler}>
                        <Nav.Link eventKey="compose">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="gray" className="bi bi-boxes" viewBox="0 0 16 16">
                                <path d="M7.752.066a.5.5 0 0 1 .496 0l3.75 2.143a.5.5 0 0 1 .252.434v3.995l3.498 2A.5.5 0 0 1 16 9.07v4.286a.5.5 0 0 1-.252.434l-3.75 2.143a.5.5 0 0 1-.496 0l-3.502-2-3.502 2.001a.5.5 0 0 1-.496 0l-3.75-2.143A.5.5 0 0 1 0 13.357V9.071a.5.5 0 0 1 .252-.434L3.75 6.638V2.643a.5.5 0 0 1 .252-.434L7.752.066ZM4.25 7.504 1.508 9.071l2.742 1.567 2.742-1.567L4.25 7.504ZM7.5 9.933l-2.75 1.571v3.134l2.75-1.571V9.933Zm1 3.134 2.75 1.571v-3.134L8.5 9.933v3.134Zm.508-3.996 2.742 1.567 2.742-1.567-2.742-1.567-2.742 1.567Zm2.242-2.433V3.504L8.5 5.076V8.21l2.75-1.572ZM7.5 8.21V5.076L4.75 3.504v3.134L7.5 8.21ZM5.258 2.643 8 4.21l2.742-1.567L8 1.076 5.258 2.643ZM15 9.933l-2.75 1.571v3.134L15 13.067V9.933ZM3.75 14.638v-3.134L1 9.933v3.134l2.75 1.571Z" />
                            </svg>
                        </Nav.Link>
                        <Nav.Link eventKey="settings">
                        {    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="grey" className="bi bi-gear-fill" viewBox="0 0 24 24">
                                 <path d="M19 2H5a3.003 3.003 0 00-3 3v14a3.003 3.003 0 003 3h14c.182 0 .362-.022.54-.055.066-.012.129-.03.193-.047a2.971 2.971 0 00.531-.187c.084-.04.165-.086.245-.133.075-.044.151-.086.222-.136l.018-.011.021-.018c.014-.011.03-.017.043-.03.007-.006.01-.015.015-.022A2.987 2.987 0 0022 19V5a3.003 3.003 0 00-3-3zm.575 18.905A1.95 1.95 0 0119 21H5a2.003 2.003 0 01-2-2v-4.725l3.763-3.762a1.753 1.753 0 012.474 0l3.405 3.404.004.007 6.97 6.969-.041.012zM21 19c0 .516-.202.982-.523 1.337l-6.769-6.768 1.056-1.055a1.787 1.787 0 012.472 0L21 16.277V19zm0-4.137l-3.057-3.056a2.75 2.75 0 00-3.886 0L13 12.862 9.944 9.806a2.753 2.753 0 00-3.888 0L3 12.86V5a2.003 2.003 0 012-2h14a2.003 2.003 0 012 2v9.863zM13.5 6a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm0 2a.5.5 0 110-1 .5.5 0 010 1z" />
                             </svg> 
                        }
                        </Nav.Link>
                        <Nav.Link eventKey="pretix">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="grey" className="bi bi-ticket-perferated" viewBox="0 0 16 16">
                                <path d="M4 4.85v.9h1v-.9H4Zm7 0v.9h1v-.9h-1Zm-7 1.8v.9h1v-.9H4Zm7 0v.9h1v-.9h-1Zm-7 1.8v.9h1v-.9H4Zm7 0v.9h1v-.9h-1Zm-7 1.8v.9h1v-.9H4Zm7 0v.9h1v-.9h-1Z" />
                                <path d="M1.5 3A1.5 1.5 0 0 0 0 4.5V6a.5.5 0 0 0 .5.5 1.5 1.5 0 1 1 0 3 .5.5 0 0 0-.5.5v1.5A1.5 1.5 0 0 0 1.5 13h13a1.5 1.5 0 0 0 1.5-1.5V10a.5.5 0 0 0-.5-.5 1.5 1.5 0 0 1 0-3A.5.5 0 0 0 16 6V4.5A1.5 1.5 0 0 0 14.5 3h-13ZM1 4.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 .5.5v1.05a2.5 2.5 0 0 0 0 4.9v1.05a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-1.05a2.5 2.5 0 0 0 0-4.9V4.5Z" />
                            </svg>
                        </Nav.Link>
                        <Nav.Link eventKey="launch">
                        <svg width="24" height="24" fill="gray" viewBox="10 4 24 24" ><path d="M19.86 12.19l4.057-4.057c1.586-1.586 5.289-2.896 6.568-1.617s-.058 4.955-1.644 6.54l-4.056 4.057c-.34.34-.318.895-.152 1.347.997 2.718-3.89 7.541-3.89 7.541l-1.95-1.948 1.83-2.779-1.83.83-3.896-3.897.803-1.857-2.751 1.857L11 16.26s4.8-4.91 7.514-3.917c.451.165 1.007.187 1.347-.153z" ></path><path d="M13.608 25.29a2.178 2.178 0 10-2.643-2.635L10 26.259z"></path>
                        </svg>
                        </Nav.Link>
                    </Nav>
                </div>

                <div className="bg-light" style={{ minWidth: 280, height: 'calc(100vh - 5rem)' }}>
                    {composeIsShown && <AccMenu unityContext={unityContext} RoomArray={RoomArray}/>}
                    {settingsIsShown && <SettingsMenu unityContext = {unityContext} RoomArray={RoomArray} />}
                    {pretixIsShown && <PretixMenu  />}
                    {launchIsShown && <LaunchMenu  unityContext = {unityContext} LobbyRoomPickerShow={showRoomPickerState} setLobbyRoomPickerShow={setRoomPickerState}/>}

                    <Badge bg="secondary" style={{position:"fixed",bottom:"14px",left:"260px"}}>0.16.1-react</Badge>
                </div>
                
            </Stack>
        </>
    )
}

export default MainNav

